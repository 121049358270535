import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import parentChild from "../../../../assets/parentChild.png";
import birds from "../../../../assets/birds.png";
import logo from "../../../../assets/logo_with_name.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getHeaders } from "../../../../redux/auth/actions";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import { useLocation, useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import moment from "moment";
import { Formik } from "formik";
import { useRef } from "react";
import app_api from "../../../../utilities/api";
const activitiesData = [
  { activity: "Brushing", rating: 5 },
  { activity: "Toileting", rating: 5 },
  { activity: "Bathing", rating: 5 },
  { activity: "2 Hours physical play", rating: 5 },
  { activity: "Frequency of JUNK FOOD", rating: 5 },
  { activity: "Combing hair", rating: 5 },
  { activity: "Undressing", rating: 5 },
  { activity: "Dressing", rating: 5 },
  { activity: "Eating with spoon", rating: 5 },
  { activity: "Eating with hand", rating: 5 },
  {
    activity:
      "Meal Time - Prep/ setting the table/ Keeping plate back in the kitchen post meal",
    rating: 5,
  },
  { activity: "Keeping shoes in the place", rating: 5 },
  { activity: "Cleaning- dusting /sweeping/ wiping the table", rating: 5 },
  {
    activity:
      "Laundry - Drying Clothes/ folding clothes/Putting clothes in the Machine",
    rating: 5,
  },
  { activity: "Washing vessels/help in arranging utensils", rating: 5 },
  { activity: "Non verbal communication", rating: 5 },
  { activity: "Command following", rating: 4 },
  { activity: "Sitting Tolerence (For Interaction)", rating: 5 },
  { activity: "Sitting Tolerance (For Task)", rating: 5 },
  { activity: "Schedule", rating: 5 },
  { activity: "Daily screen time : in hrs", rating: 5 },
  { activity: "2 - Understands pointing (Joint attention)", rating: 5 },
  { activity: "Social Interaction", rating: 7 },
  { activity: "Oromotor", rating: 5 },
  { activity: "Answering WH questions", rating: 5 },
];

const QPRsheet = () => {
  const { patient_id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queary = searchParams.get("q");
  const dispatch = useDispatch();
  const [qpr, setQpr] = useState({
    loading: true,
    data: [],
    reportTable: {},
    lastIndex: {},
    lastQpr: "",
  });
  const [qprDate, setQprDate] = useState({
    data: [],
    loading: true,
  });

  // async function getQprDate() {
  //   try {
  //     const res = await app_api.get(
  //       `/api/form/form-builder/qpr-sequence/${patient_id}`
  //     );

  //     setQprDate({
  //       data: res.data.data,
  //       loading: false,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setQprDate((prev) => ({ ...prev, loading: false }));
  //   }
  // }
  // useEffect(() => {
  //   getQprDate();
  // }, []);

  function processGraphData(graphData, activitiesData) {
    const processedActivities = {};
    const graphEntries = {};

    graphData.forEach((entry) => {
      graphEntries[entry.datasets.label.toLowerCase().trim()] = entry;
    });

    activitiesData.forEach((activity) => {
      const activityKey = activity.activity.toLowerCase().trim();

      if (!processedActivities[activityKey]) {
        const matchingGraphEntry = graphEntries[activityKey];

        if (matchingGraphEntry) {
          if (!matchingGraphEntry.labels.includes("Targeted Outcomes")) {
            matchingGraphEntry.labels.push("Targeted Outcomes");
          }

          if (
            !matchingGraphEntry.datasets.data.includes(
              activity.rating.toString()
            )
          ) {
            matchingGraphEntry.datasets.data.push(activity.rating.toString());
          }
        }

        processedActivities[activityKey] = true;
      }
    });

    return graphData;
  }

  //console.log(qpr.lastIndex);
  useEffect(() => {
    (async () => {
      const headers = await dispatch(getHeaders());
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reporting/qpr/${patient_id}`,
        {
          qpr_sequence: queary.split("%20")[0].split(" "),
        },
        headers
      );
      const lastQpr =
        "P" + (response.data.data.final_data?.length - 1).toString();
      await setQpr({
        loading: false,
        data: response.data.data,
        reportTable: response.data.data.final_data.find(
          (item) => item.qpr_sequence === "Pre-Program"
        ),
        lastIndex: response.data.data.final_data.find(
          (item) =>
            item.qpr_sequence ===
            "P" + (response.data.data.final_data?.length - 1).toString()
        ),
        lastQpr: "P" + (response.data.data.final_data?.length - 1).toString(),
      });
      const res = await app_api.get(
        `/api/form/form-builder/qpr-sequence/${patient_id}?q=${lastQpr}`
      );

      setQprDate({
        data: res.data.data,
        loading: false,
      });
    })();
  }, []);

  const divRef = useRef(null);

  return qpr.loading ? (
    <FallingLinesLoader />
  ) : (
    <>
      <button
        onClick={() => {
          window.print();
        }}
        className="bg-blue-500 text-white px-4 py-2 rounded-md float-left"
      >
        Print
      </button>
      <div ref={divRef} className="min-h-screen  w-full pt-4 pr-4 pl-4">
        <h1 className="text-3xl text-right">
          <img className="w-[15%] float-right" src={logo} alt="logo" />
        </h1>
        <div className="w-full flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center">
            E-NABLE PARENT COACHING PROGRAM
          </h1>
          <img src={parentChild} alt="ParentImage" />
        </div>
        <div className="w-full mt-5 mb-20">
          <table className="table-fixed w-full">
            <tbody>
              <tr>
                <td className="w-1/4 py-2 px-4 border">Report Date:</td>
                <td className="w-3/4 py-2 px-4 border">
                  {moment(qpr.data.fQPR.createdAt).format("DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td className="w-1/4 py-2 px-4 border">Name of Child:</td>
                <td className="w-3/4 py-2 px-4 border">
                  {/* {qpr?.reportTable?.other_data?.[0]?.Surname
                  ? qpr?.reportTable?.other_data?.[0]?.Surname
                  : "" +
                    " " +
                    qpr?.reportTable?.other_data?.[0]?.["Middle Name"]
                  ? 
                  : "" + " " + qpr?.reportTable?.other_data[0]["First name "]} */}
                  {qpr?.reportTable?.other_data[0]?.["First name "]}{" "}
                  {qpr?.reportTable?.other_data[0]?.["Middle Name"]}{" "}
                  {qpr?.reportTable?.other_data?.[0]?.Surname}
                </td>
              </tr>
              <tr>
                <td className="w-1/4 py-2 px-4 border">Reg. No:</td>
                <td className="w-3/4 py-2 px-4 border">N/A</td>
              </tr>
              <tr>
                <td className="w-1/4 py-2 px-4 border">Gender:</td>
                <td className="w-3/4 py-2 px-4 border">
                  {qpr?.reportTable?.other_data?.[0]?.Gender
                    ? qpr?.reportTable?.other_data?.[0]?.Gender
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="w-1/4 py-2 px-4 border">DOB:</td>
                <td className="w-3/4 py-2 px-4 border">
                  {qpr?.reportTable?.other_data?.[0]?.DOB
                    ? moment(qpr?.reportTable?.other_data?.[0]?.DOB).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="w-1/4 py-2 px-4 border">Referred By:</td>
                <td className="w-3/4 py-2 px-4 border">
                  {qpr?.reportTable?.other_data?.[0]?.["Referred By"]
                    ? qpr?.reportTable?.other_data?.[0]?.["Referred By"]
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="w-1/4 py-2 px-4 border">Age:</td>
                <td className="w-3/4 py-2 px-4 border">
                  {qpr?.reportTable?.other_data?.[0]?.Age
                    ? qpr?.reportTable?.other_data?.[0]?.Age
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full mt-20  flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center">
            Concerns Noted on Commencement of the Program:{" "}
            {qprDate.data.length > 0
              ? moment(
                  qprDate.data.find((d) => d.sequence === "Pre-Program")?.date
                ).format("MMMM YYYY")
              : ""}
          </h1>
        </div>
        <div className="w-full mb-60">
          <table className="table-fixed w-full">
            <tbody>
              {qpr?.reportTable?.other_data?.[1] &&
                Object?.keys(qpr?.reportTable?.other_data?.[1]).map((key) => (
                  <tr key={key}>
                    <td className="w-1/4 py-2 px-4 border">{key}</td>
                    <td className="w-3/4 py-2 px-4 border">
                      {qpr?.reportTable?.other_data?.[1]?.[key] || "N/A"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="w-full mt-60 flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center">
            Improvements Noted at Completion of Program:{" "}
            {qprDate.data.length > 0
              ? moment(qprDate.data[qprDate.data.length - 1].date).format(
                  "MMMM YYYY"
                )
              : ""}
          </h1>
        </div>
        <div className="w-full mb-60">
          {/* {console.log(qpr?.lastIndex?.other_data?.[qpr?.lastIndex?.other_data])} */}
          <table className="table-fixed w-full">
            <tbody>
              {qpr?.lastIndex?.other_data?.[1] !== undefined &&
                Object.keys(
                  qpr?.lastIndex?.other_data?.[
                    qpr?.lastIndex?.other_data.length - 1
                  ]
                )
                  // .filter(
                  //   (d) =>
                  //     typeof qpr?.lastIndex?.other_data?.[1]?.[d] !== "string"
                  // )
                  .map(
                    (key) =>
                      qpr?.lastIndex?.other_data?.[
                        qpr?.lastIndex?.other_data.length - 1
                      ] && (
                        <tr key={key}>
                          <td className="w-1/4 py-2 px-4 border">{key}</td>
                          <td className="w-3/4 py-2 px-4 border">
                            {qpr?.lastIndex?.other_data?.[
                              qpr?.lastIndex?.other_data.length - 1
                            ][key] || "N/A"}
                          </td>
                        </tr>
                      )
                  )}
            </tbody>
          </table>
        </div>
        <div className="w-full mt-20  flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center">
            Child’s journey on E-nable Parent Coaching Program
          </h1>
        </div>

        <div className="w-full  grid grid-cols-1 md:grid-cols-2 place-items-center">
          {qpr.data.final_graph_data.length > 0 &&
            processGraphData(qpr?.data?.final_graph_data, activitiesData)
              ?.filter((g) => g.datasets.label !== "graph")
              .map((item, index) => (
                <div
                  key={index}
                  className="w-1/2 flex flex-col mt-10 justify-center items-center"
                >
                  <label className="text-xl text-center text-gray-500">
                    {item.datasets.label}
                  </label>
                  <BarChart lables={item.labels} dataset={item.datasets} />
                </div>
              ))}
        </div>
        <Formik
          initialValues={{
            box: "",
          }}
          onSubmit={(values, action) => {}}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="w-full mt-10  flex flex-col justify-center items-center border border-gray-500 "
            >
              <h1
                className="text-2xl w-full text-center first-letter:
          border-b border-gray-500 p-5
        "
              >
                Development Pediatrician's Review and Recommendation:
              </h1>
              <textarea
                name="box"
                className="w-full m-0 border-none outline-none"
                id=""
                onChange={formik.handleChange}
                value={formik.values.box}
                cols="30"
                rows="10"
              ></textarea>
              {formik.values.box && (
                <div className="w-full flex justify-end p-4 ring-0 ring-white outline-none focus:outline-none focus:border-none focus:ring-0 focus:ring-opacity-50">
                  <span>
                    <Button type={"submit"}>Save</Button>
                  </span>
                </div>
              )}{" "}
            </form>
          )}
        </Formik>

        <h1 className="text-2xl w-full text-left first-letter">
          Name of the Developmental Pediatrician: Dr.{" "}
          {qpr.data.last_opd?.[0]?.appointment?.doctor?.first_name
            ? qpr.data.last_opd?.[0]?.appointment?.doctor?.first_name +
              " " +
              qpr.data.last_opd?.[0]?.appointment?.doctor?.last_name
            : "N/A"}
        </h1>
        <div className="w-full mt-10  flex  justify-between items-center  ">
          <span className="text-sm text-left text-blue-500">
            www.newhorizonscdc.in
          </span>
          <img src={birds} alt="birds" />
        </div>
      </div>
    </>
  );
};

export default QPRsheet;

const BarChart = ({ lables, dataset }) => {
  // console.log(lables);
  const getBackgroundColor = (labels) => {
    const colors = [];
    labels.forEach((label) => {
      if (label.startsWith("T")) {
        colors.push("orange");
      } else {
        colors.push("blue");
        // colors.push("gray");
      }
    });
    return colors;
  };
  const data = {
    labels: lables,
    datasets: [
      {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: getBackgroundColor(lables),
      },
    ],
  };
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 8,
        stepSize: 0,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const BigBarChart = () => {
  const data = {
    sleep_schedule: [70, 85, 100],
    diet: [80, 90, 95],
    physicalPay: [60, 70, 80],
  };

  const colors = ["blue", "orange", "gray"];
  const labels = ["Pre-Program", "Post P1", "Targeted Outcome"];

  const datasets = Object.keys(data).map((key, index) => {
    return {
      label: key,
      data: data[key],
      backgroundColor: colors[index],
    };
  });

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};
