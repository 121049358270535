import Text_box from "./Fields/Text_box";
import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import File_Input from "./Fields/FIle_input";
import Number_input from "./Fields/Number_input";
import Radio_input from "./Fields/Radio_input";
import Checkbox_input from "./Fields/Checkbox_input";
import Heading from "./Fields/Heading";
import Select_Input from "./Fields/Select_Input";
import Text_area_input from "./Fields/Text_area_input";
import EmailInput from "./Fields/Email_input";
import Date_time_input from "./Fields/Date_time_input";
import Date_input from "./Fields/Date_input";
import Password_Input from "./Fields/Password_input";
import CheckIcon from "@mui/icons-material/Check";
import Relation_master from "./Fields/Relation_master";
import LineChart from "../../../components/Chart/Line";
import Modal from "../../../components/Modal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { removeLeadingComma } from "../../../utilities/utilities";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import { toast } from "react-hot-toast";
import moment from "moment";
const api = process.env.REACT_APP_API_URL;

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    purchase_order_id: "",
    storage_location_id: "",
    quantity: "",
    item_id: "",
  },
};
export default function Form_submit(props) {
  // console.log(props);
  const [sequence, setsequence] = useState([]);
  const [modal, setModal] = useState(initialModalState);
  const [QprModal, setQprModal] = useState(initialModalState);
  const [previousModal, setpreviousModal] = useState(initialModalState);
  const [formdata, setformdata] = useState({});
  const [hid_param, sethid_param] = useState({});
  const [success, set_success] = useState(false);
  const [data, setdata] = useState({});

  const [chartData, setChartData] = useState({
    loading: true,
    data: [],
  });
  const [viewPrevious, setviewPrevious] = useState({
    loading: true,
    data: [],
  });
  const [viewPreviousQPR, setviewPreviousQPR] = useState({
    loading: true,
    data: [],
  });
  const { patientId } = props;
  const { id } = useParams();
  const formgroup = props.formgroup;
  let all_fields = props.fields;

  const handlesubmit = async (e) => {
    e.preventDefault();
    let send_data = { form_group_id: parseInt(formgroup), data: [] };
    Object.keys(formdata).map((sinkey) => {
      let obj = {};
      obj.value = formdata[sinkey];
      obj.reference_column_name = sinkey;
      send_data.data.push(obj);
    });
    let send_data2 = { form_group_id: parseInt(formgroup), data: [] };
    Object.keys(data).map((dat) => {
      let obj = {};
      if (formdata[data[dat]["ref_id"]]) {
        obj.value = formdata[data[dat]["ref_id"]];
      } else {
        obj.value =
          data[dat].type == "number"
            ? parseInt(data[dat].defaultValue)
            : data[dat].defaultValue == ""
            ? null
            : data[dat.defaultValue];
      }
      obj.reference_column_name = data[dat]["ref_id"];
      send_data2.data.push(obj);
      // console.log(formdata);
    });

    // Log the data to see what is being sent
    console.log("Data being sent to backend:", send_data2);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/form/form-builder/save/form-data/${props.pid}`,
        [send_data2]
      )
      .then((resp) => {
        if (resp.data.type == "success") {
          // toast.success(
          //   Object.keys(formdata)
          //     .filter((d) => typeof formdata[d] === "string")
          //     .map((sinkey) => {
          //       if (formdata[sinkey])
          //         return (
          //           <div>
          //             <label>{formdata[sinkey]}</label>
          //           </div>
          //         );
          //     })
          // );
          set_success(true);
          // window.location.reload(false);
        }
      });

    props.reFetch();
  };

  function record_data(val, id) {
    // console.log(id.includes("date_time") && val ? new Date(val) : val, id);
    console.log(`Value: ${val}`);
    if (val !== null) {
      setformdata((prev) => ({
        ...prev,
        [id]: id.includes("date_time") && val ? new Date(val) : val,
      }));
    }
  }

  console.log("formdata<><><><>", formdata)


  useEffect(() => {
    getdata();
    getPreviousQPR();
    getPrevious();
    async function get_dat() {
      let create_data = {};
      let temp_formdata = {};
      Object.keys(all_fields).map((field) => {
        temp_formdata[all_fields[field].reference_column_name] =
          all_fields[field].previous_value;
        setsequence((ids) => [...ids, all_fields[field].id]);
        let target_id = all_fields[field].id;
        create_data[target_id] = {};
        create_data[target_id].id = all_fields[field].id;
        create_data[target_id].fieldname = all_fields[field].name;
        create_data[target_id].label = all_fields[field].label;
        create_data[target_id].type = all_fields[field].type;
        create_data[target_id].child = all_fields[field].child;
        create_data[target_id].parent = all_fields[field].parent;

        create_data[target_id].placeholder = all_fields[field].placeholder;
        create_data[target_id].defaultValue = all_fields[field].defaultValue;

        create_data[target_id].required = all_fields[field].required;
        create_data[target_id].ref_id = all_fields[field].reference_column_name;

        create_data[target_id].label = all_fields[field].label;
        create_data[target_id].max = all_fields[field].max;
        create_data[target_id].min = all_fields[field].min;
        let tempobj = {};
        if (all_fields[field].form_field_options) {
          all_fields[field].form_field_options.forEach((opt) => {
            tempobj[opt.id] = opt.option;
          });
        }
        create_data[target_id].options = tempobj;
        create_data[target_id].maxlength = all_fields[field].maxLength;
        create_data[target_id].minlength = all_fields[field].minLength;
        create_data[target_id].file_Type = all_fields[field].fileType;
        create_data[target_id].file_Type = all_fields[field].fileType;
        create_data[target_id].editmode = 0;
        // }
      });
      console.log("create-data<><><>", create_data);
      setdata(create_data);
      setformdata((prev) => ({
        ...prev,
        ...temp_formdata,
      }));
    }

    console.log("final forma data", formdata)

    get_dat();
    if (
      (props.fields.length > 0 && props.form_name.includes("Demographic")) ||
      props.form_name.includes("Demographic Details")
    ) {
      props.fields
        .filter((f) => f.previous_value === "" || !f.previous_value)
        .map((field) => {
          if (
            !field.previous_value ||
            (field.previous_value &&
              field.previous_value !== null &&
              field.reference_column_name !== null &&
              field.reference_column_name !== "")
          )
            record_data(field.defaultValue, field.reference_column_name);
        });
    }
  }, []);

  function remapData(data) {
    return data.map((item) => {
      if (item["Arm Span (CMS)"] !== undefined) {
        item["Arm span (cms)"] = item["Arm Span (CMS)"];
        delete item["Arm Span (CMS)"];
      }

      return {
        bmi: parseInt(item.BMI),
        height: parseInt(item["Height (cm)"]),
        weight: parseInt(item["Weight (kg)"]),
        upsegment: parseInt(item["Upper segment (cms)"]),
        lowsegment: parseInt(item["Lower segment (cms)"]),
        head_curc: item["Head circumference (cms)"],
        chest_curcum: item["Chest circumference (cms)"],
        mid_air: item["Mid arm circumference (cms)"],
        ratio: item["US/LS Ratio"],
        arm: item["Arm span (cms)"], 
        id: item.id,
        month: item["date"],
      };
    });
  }

  async function getdata() {
    try {
      const response = await axios.get(
        `${api}/api/reporting/bmi-graph/${patientId}`
      );
      console.log("🚀 ~ getdata ~ response:", response)
      setChartData({
        data: response.data.data,
        loading: false,
      });
        
    } catch (error) {}
  }

  
  async function getPrevious() {
    try {
      const response = await axios.get(
        `${api}/api/reporting/cc-prev-data/${patientId}`
      );
      setviewPrevious({
        data: response.data.data,
        loading: false,
      });
    } catch (error) {}
  }
  const BarChart = ({ lables, dataset }) => {
    const data = {
      labels: lables,
      datasets: [
        {
          label: dataset.label,
          data: dataset.data,
          backgroundColor: ["blue", "orange", "gray"],
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: 8,
          stepSize: 0,
        },
      },
    };

    return <Bar data={data} options={options} />;
  };

  async function getPreviousQPR() {
    try {
      const response = await axios.get(
        `${api}/api/reporting/qpr-graph-data/${id}`
      );
      setviewPreviousQPR({
        data: response.data.data,
        loading: false,
      });
    } catch (error) {}
  }

  console.log("ChartDataaaaaaa", chartData.data);

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Modal
        size="max-w-7xl"
        title={`BMI Chart`}
        open={state}
        setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
      >
        <LineChart cdata={remapData(chartData.data)} />{" "}
      </Modal>
    );
  };

  const ViewPreviousModal = () => {
    const { type, state, data, edit_id } = previousModal;
    return (
      <Modal
        title={`View Previous`}
        size="lg:max-w-5xl md:max-w-xl overflow-x-scroll"
        open={state}
        setOpen={() => setpreviousModal((prev) => ({ ...prev, state: false }))}
      >
        {viewPrevious.loading && viewPrevious.data.length > 0 ? (
          <FallingLinesLoader />
        ) : (
          viewPrevious.data
            .filter(
              (v) =>
                v.Social_Interaction.filter((d) => d !== null).length > 0 ||
                v.Non_Verbal_Communication.filter((d) => d !== null).length >
                  0 ||
                v.house_hold_chores.filter((d) => d !== null).length > 0 ||
                v.schedule.filter((d) => d !== null).length > 0 ||
                v.screen_time.filter((d) => d !== null).length > 0 ||
                v.Frequency_of_JUNK_FOOD.filter((d) => d !== null).length > 0 ||
                v.abls.filter((d) => d !== null).length > 0 ||
                v.physical.filter((d) => d !== null).length > 0
            )
            .map((item) => (
              <>
                <div className="mt-3 shadow ring-1  ring-black ring-opacity-5">
                  <div className="p-2 flex items-center justify-start">
                    <h5>
                      <span className="text-gray-700">
                        Date : <span className="text-gray-700"></span>
                        {item.date}
                      </span>
                    </h5>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">
                          Social Interaction
                        </span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.Social_Interaction.filter(
                          (e) => e !== null
                        )?.map((element, i) => {
                          const header = Object.keys(element);
                          return (
                            <>
                              {header?.length &&
                                header
                                  .filter((e) => element[e] !== "")
                                  ?.map((h) => {
                                    const val = element[h];
                                    return (
                                      <tr key={h}>
                                        <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                          <p
                                            className="ml-4 text-sm font-medium text-gray-900 truncate"
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {h}
                                          </p>
                                        </td>

                                        <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                          <p
                                            className="ml-4 text-sm font-medium text-gray-900"
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {removeLeadingComma(val)}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">
                          Non Verbal Communication
                        </span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.Non_Verbal_Communication.filter(
                          (e) => e !== null
                        )?.map((element, i) => {
                          const header = Object.keys(element);
                          return (
                            <>
                              {header?.length &&
                                header
                                  .filter((e) => element[e] !== "")
                                  ?.map((h) => {
                                    const val = element[h];
                                    return (
                                      <tr key={h}>
                                        <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                          <p
                                            className="ml-4 text-sm font-medium text-gray-900 truncate"
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {h}
                                          </p>
                                        </td>

                                        <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                          <p
                                            className="ml-4 text-sm font-medium text-gray-900"
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {removeLeadingComma(val)}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">House Hold Chores</span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.house_hold_chores
                          .filter((e) => e !== null)
                          ?.map((element, i) => {
                            const header = Object.keys(element);
                            return (
                              <>
                                {header?.length &&
                                  header
                                    .filter((e) => element[e] !== "")
                                    ?.map((h) => {
                                      const val = element[h];
                                      return (
                                        <tr key={h}>
                                          <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900 truncate"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {h}
                                            </p>
                                          </td>

                                          <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {removeLeadingComma(val)}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">Schedule</span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.schedule
                          .filter((e) => e !== null)
                          ?.map((element, i) => {
                            const header = Object.keys(element);
                            return (
                              <>
                                {header?.length &&
                                  header
                                    .filter((e) => element[e] !== "")
                                    ?.map((h) => {
                                      const val = element[h];
                                      return (
                                        <tr key={h}>
                                          <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900 truncate"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {h}
                                            </p>
                                          </td>

                                          <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {removeLeadingComma(val)}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">
                          Activities of daily living
                        </span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.abls
                          .filter((e) => e !== null)
                          ?.map((element, i) => {
                            const header = Object.keys(element);
                            return (
                              <>
                                {header?.length &&
                                  header
                                    .filter((e) => element[e] !== "")
                                    ?.map((h) => {
                                      const val = element[h];
                                      return (
                                        <tr key={h}>
                                          <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900 truncate"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {h}
                                            </p>
                                          </td>

                                          <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {removeLeadingComma(val)}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">
                          2 Hours physical play
                        </span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.physical
                          .filter((e) => e !== null)
                          ?.map((element, i) => {
                            const header = Object.keys(element);
                            return (
                              <>
                                {header?.length &&
                                  header
                                    .filter((e) => element[e] !== "")
                                    ?.map((h) => {
                                      const val = element[h];
                                      return (
                                        <tr key={h}>
                                          <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900 truncate"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {h}
                                            </p>
                                          </td>

                                          <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {removeLeadingComma(val)}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">Screen Time</span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.screen_time
                          .filter((e) => e !== null)
                          ?.map((element, i) => {
                            const header = Object.keys(element);
                            return (
                              <>
                                {header?.length &&
                                  header
                                    .filter((e) => element[e] !== "")
                                    ?.map((h) => {
                                      const val = element[h];
                                      return (
                                        <tr key={h}>
                                          <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900 truncate"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {h}
                                            </p>
                                          </td>

                                          <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                            <p
                                              className="ml-4 text-sm font-medium text-gray-900"
                                              style={{
                                                overflowWrap: "break-word",
                                              }}
                                            >
                                              {removeLeadingComma(val)}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="border border-gray-200 shadow-sm rounded-md mt-4">
                    <div className="ml-2 p-2 flex items-center justify-start">
                      <h5>
                        <span className="text-gray-700">
                          Frequency of Junk food
                        </span>
                      </h5>
                    </div>
                    <table className="min-w-full  overflow-y-auto divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                          <th
                            scope="col"
                            className="text-center text-sm font-semibold text-gray-900 p-1"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {item?.Frequency_of_JUNK_FOOD.filter(
                          (e) => e !== null
                        )?.map((element, i) => {
                          const header = Object.keys(element);
                          return (
                            <>
                              {header?.length &&
                                header
                                  .filter((e) => element[e] !== "")
                                  ?.map((h) => {
                                    const val = element[h];
                                    return (
                                      <tr key={h}>
                                        <td className="whitespace-nowrap text-left h-max text-sm font-medium text-gray-900 p-1">
                                          <p
                                            className="ml-4 text-sm font-medium text-gray-900 truncate"
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {h}
                                          </p>
                                        </td>

                                        <td className="whitespace-nowrap text-left text-sm font-medium text-gray-900 p-1">
                                          <p
                                            className="ml-4 text-sm font-medium text-gray-900"
                                            style={{
                                              overflowWrap: "break-word",
                                            }}
                                          >
                                            {removeLeadingComma(val)}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ))
        )}
      </Modal>
    );
  };
  const ViewQPRModal = () => {
    const { type, state, data, edit_id } = QprModal;
    function updateLabelsArray(finalGraphData, withFormDate) {
      const updatedFinalGraphData = finalGraphData.map((graphItem) => {
        const labels = graphItem.labels || [];
        withFormDate.forEach((item) => {
          if (labels.includes(item.qpr_sequence_graph)) {
            labels[labels.indexOf(item.qpr_sequence_graph)] = moment(
              item.form_date
            )
              .utcOffset(0)
              .format("DD/MM/YYYY");
          }
        });

        return graphItem;
      });

      return updatedFinalGraphData;
    }

    return (
      <Modal
        title={`View Previous Qpr`}
        size="max-w-4xl"
        open={state}
        setOpen={() => setQprModal((prev) => ({ ...prev, state: false }))}
      >
        {viewPreviousQPR.loading ? (
          <FallingLinesLoader />
        ) : (
          viewPreviousQPR.data.final_graph_data.length > 0 &&
          updateLabelsArray(
            viewPreviousQPR?.data?.final_graph_data,
            viewPreviousQPR.data.with_form_date
          )
            ?.filter((g) => g.datasets.label !== "graph")
            ?.map((item, index) => (
              <div
                key={index}
                className="w-1/2 grid grid-cols-2 mt-10 justify-center items-center"
              >
                {/* {console.log(updateLabelsArray(viewPreviousQPR.data.final_graph_data,viewPreviousQPR.data.with_form_date))} */}
                <label className="text-xl text-center text-gray-500">
                  {item.datasets.label}
                </label>
                <BarChart lables={item.labels} dataset={item.datasets} />
              </div>
            ))
        )}
      </Modal>
    );
  };

  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {/*content*/}
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        {/*header*/}
        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"></div>
        {/*body*/}
        {renderModal()}
        <div className="relative p-6 flex-auto">
          {ViewPreviousModal()}
          {ViewQPRModal()}
          <form onSubmit={handlesubmit}>
            <div className="w-full flex items-end justify-end h-0 mt-6 ">
              {props.form_name.includes("Demographic") && (
                <button
                  className="text-center py-1 px-5 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
                  type="button"
                  onClick={() =>
                    setModal((prev) => ({
                      state: true,
                    }))
                  }
                >
                  BMI Chart
                </button>
              )}
              {props.form_name.includes("Current Concerns") && (
                <div className="flex flex-row gap-2">
                  <button
                    className="text-center py-1 px-5 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700"
                    type="button"
                    onClick={() =>
                      setpreviousModal((prev) => ({
                        state: true,
                      }))
                    }
                  >
                    View previous
                  </button>
                  <button
                    className="text-center py-1 px-5 rounded-full border text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700 disabled:opacity-80 disabled:cursor-not-allowed"
                    type="button"
                    disabled={viewPreviousQPR.loading}
                    onClick={() =>
                      setQprModal((prev) => ({
                        state: true,
                      }))
                    }
                  >
                    {viewPreviousQPR.loading ? "Generating.." : "View Qpr"}
                  </button>
                </div>
              )}
            </div>
            <div className="space-y-6 sm:space-y-5">
              {sequence.map((e, index) => {
                switch (data[e].type) {
                  case "text":
                    return (
                      <Text_box
                        all_fields={data}
                        record_change={record_data}
                        current_data={formdata}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "heading":
                    return (
                      <Heading
                        current_data={formdata}
                        all_fields={data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "date":
                    return (
                      <Date_input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "file":
                    return (
                      <File_Input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "date-time":
                    return (
                      <Date_time_input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "number":
                    return (
                      <Number_input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "radio":
                    return (
                      <Radio_input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "checkbox":
                    return (
                      <Checkbox_input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "select":
                    return (
                      <Select_Input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "RelationMaster":
                    return (
                      <Relation_master
                        all_fields={data}
                        current_data={formdata}
                        flag={true}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "text-area":
                    return (
                      <Text_area_input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "email":
                    return (
                      <EmailInput
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                  case "password":
                    return (
                      <Password_Input
                        all_fields={data}
                        current_data={formdata}
                        record_change={record_data}
                        config={data[e]}
                        key={`${data[e].fieldname}_${index}`}
                      />
                    );
                    break;
                }
              })}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                {/* // ! Hiding this button for clinic admin */}
                {/* // ! Hiding this button for Locked is True */}
                {props.locked === false ? (
                  user.role === "CLINICAL_ADMIN" ? null : (
                    <button
                      type="submit"
                      class="inline-block px-6 py-2.5 bg-indigo-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Submit
                    </button>
                  )
                ) : null}
              </div>
            </div>
          </form>
        </div>
        {/*footer*/}
      </div>

      {success ? (
        <div>
          <div class=" z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div class="z-50 fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CheckIcon />
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        class="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Form Submitted Succesfully!
                      </h3>
                      <div class="mt-2">
                        {/* {console.log(formdata))} */}
                        <p class="text-sm text-gray-500">
                          {Object.keys(formdata).map((sinkey) => {
                            if (formdata[sinkey])
                              return (
                                <div>
                                  <label>
                                    {typeof formdata[sinkey] !== "string"
                                      ? JSON.stringify(formdata[sinkey])
                                      : formdata[sinkey]}
                                  </label>
                                </div>
                              );
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={() => {
                      set_success(false);
                    }}
                    class="mt-3 inline-flex w-full bg-indigo-300 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
